const getProducts = () => 
    fetch('/api/products')
      .then((response) => response.json())
      .then((jsonResponse) => jsonResponse.items)
      .then((products) => products)
      .catch((e) => {
        console.log('Error while fetching products list', e);
        });

export default getProducts
