import React from 'react';

const AboutUsContent = () => {
  return (
    <div className="about-us__content u-margin-bottom-big">
      <h2 className="heading-3 heading-3--dark about-us__heading">O nas</h2>
      <p className="about-us__description">
        Sprzedażą części samochodowych zajmujemy się już od 20 lat. Naszym celem jest to by klient był zawsze zadowolony z naszej obsługi. W związku z tym
        nieustannie podnosimy swoje kwalifikacje zawodowe, dlatego zapewniamy najwyższy stopień profesjonalizmu w zakresie obsługi klienta.
      </p>
      <p className="about-us__description">
        Powoduje to, że pomyłki i błędy przy zakupie lub zamawianiu części ograniczyliśmy praktycznie do zera. Na każdym etapie zamówienia czy zakupów służymy
        Państwu wyczerpującymi informacjami na temat towaru jego jakości, ceny, zamienników itp….
      </p>
      <p className="about-us__description">
        Dzięki nowoczesnym systemom logistycznym- jeśli nie posiadamy towaru na miejscu, jest on dostarczany w tym samym dniu lub najpóźniej następnego dnia.
        Współpracujemy z największymi hurtowniami oraz bezpośrednio z producentami, dlatego dysponujemy bardzo szeroką ofertę części do wszystkich samochodów.
      </p>
      <p className="about-us__description">
        Oferujemy tylko nowe części najwyższej jakości. Są to produkty znanych na całym świecie, dostawców części samochodowych, produkujących na rynek wtórny
        jak i na pierwszy montaż. Spełniają one najwyższe normy jakości i bezpieczeństwa eksploatacji.
      </p>
    </div>
  );
};

export default AboutUsContent;
