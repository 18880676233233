import React from 'react';
import {Form} from 'formik';
import Input from './Input';

const ContactForm = ({errors, isSubmitting, touched}) => {
  const hasErrors = Object.entries(errors).length > 0 && errors.constructor === Object;

  return (
    <Form className="form">
      <Input name="name" error={errors['name']} touched={touched['name']} label="Imię i nazwisko" placeholder="Wpisz swoje imię i nazwisko"></Input>
      <Input name="email" error={errors['email']} touched={touched['email']} type="email" label="Adres E-mail" placeholder="Wpisz adres email"></Input>
      <Input
        error={errors['message']}
        touched={touched['message']}
        name="message"
        label="Treść wiadomości"
        placeholder="Wpisz treść wiadomości"
        component="textarea"
      ></Input>

      <button className="btn btn__primary form__btn" disabled={hasErrors} type="submit">
        {isSubmitting ? 'Wysyłanie...' : 'Wyślij'}
      </button>
    </Form>
  );
};

export default ContactForm;
