import React from 'react';
import { useRecoilValue } from "recoil";
import {CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {useMedia} from './useMedia';
import { recommendProductsState } from '../store/selectors/products';

const Recommend = () => {
  const recommendProducts = useRecoilValue(recommendProductsState);
  const largeDevice = useMedia('(min-width: 896px)');
  const mobileDevice = useMedia('(min-width: 480px)');

  const mediaDevices = () => {
    if (largeDevice) return 3;
    if (mobileDevice) return 2;
    return 1;
  };

  return (
    <section className="recommend">
      <h3 className="heading-3 heading-3--dark recommend__header">Polecane produkty</h3>
      <div className="recommend__container">
        <CarouselProvider
          infinite={true}
          isPlaying={true}
          interval={3000}
          visibleSlides={mediaDevices()}
          naturalSlideWidth={100}
          naturalSlideHeight={80}
          totalSlides={recommendProducts.length}
          isIntrinsicHeight={true}
        >
          <Slider>
            {recommendProducts.map((el) => (
              <Slide key={el.id} index={el.id}>
                <RecommendItem el={el}></RecommendItem>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </div>
    </section>
  );
};

const RecommendItem = ({el}) => {
  return (
    <div className="recommend__item">
      <span className="recommend__label">{el.cost} zł</span>
      <img src={el.image} alt="" className="recommend__img" />
      <h4 className="heading-4 recommend__title">{el.name}</h4>
      <p className="recommend__description">{el.description}</p>
    </div>
  );
};

export default Recommend;
