import React from 'react';

const Map = ({className}) => {
  return (
    <iframe
      className={className}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8591.773272458506!2d15.547164896850052!3d54.16956245432848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4700150b86f06ca9%3A0x20131f3f583448f7!2sSienkiewicza%2014%2C%2078-100%20Ko%C5%82obrzeg!5e0!3m2!1spl!2spl!4v1592387832779!5m2!1spl!2spl"
      frameBorder="0"
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
      title="map"
    ></iframe>
  );
};

export default Map;
