import React from 'react';

const ContentItem = ({title, img, subTitle, children, desc, reverse}) => {
  const contentClassName = (reverse) => {
    return reverse ? 'content__item content__item--reverse' : 'content__item';
  };
  return (
    <div className={contentClassName(reverse)}>
      <h4 className="heading-4 content__title">{title}</h4>
      {desc && <p className="content__description">{desc}</p>}
      {img && <img src={img} alt="" className="content__img" />}
      {children && (
        <div className="content__content">
          <h4 className="heading-4 content__sub-title">{subTitle}</h4>
          {children}
        </div>
      )}
    </div>
  );
};

export default ContentItem;
