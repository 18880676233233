import {withFormik} from 'formik';
import Contact from './Contact';
import * as Yup from 'yup';

const Formik = withFormik({
  mapPropsToValues: ({name, email, message}) => {
    return {
      name: name || '',
      email: email || '',
      message: message || '',
    };
  },

  validationSchema: Yup.object().shape({
    name: Yup.string().min(3, 'To pole musi mieć minimum 3 znaki').max(255, 'Treść wiadomości jest za długa').required('To pole jest wymagane'),
    message: Yup.string().min(10, 'Treść wiadomości jest za krótka').max(255, 'Treść wiadomości jest za długa').required('To pole jest wymagane'),
    email: Yup.string().email('Podaj poprawny adres email').required('Adres email jest wymagany'),
  }),

  handleSubmit: ({name, email, message}, {resetForm}) => {
    const data = {name, email, message};

    fetch('https://us-central1-motoluczak-1278d.cloudfunctions.net/app/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        resetForm();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  },
})(Contact);

export default Formik;
