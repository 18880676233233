import { useEffect, useRef, useState } from 'react';
import useInView from 'react-cool-inview';

const apiPaginationAdapter = async (page = 1, apiCall) => new Promise((resolve, reject) => {
  apiCall().then(data => {
    console.log(page)
    setTimeout(() => {
      const items = data.slice((page - 1) * 10, page * 10)
      console.log(items)
      resolve({data: items, rest: data.length - items.length});
  }, 2000);
  })
})

export const useInfiniteLoading = (prevItems, getItemsAPI, setProducts) => {
  const [newItems, setNewItems] = useState([]);
  const [pageToLoad, setPage] = useState(1);
  const initialPageLoaded = useRef(false);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(() => pageToLoad !== 1);

  const loadItems = async (pageToLoad, itemCombineMethod) => {
    const {data, rest} = await apiPaginationAdapter(pageToLoad, getItemsAPI)
    //setHasNext(data.totalPages > pageToLoad);
    setHasNext(rest > 0);
    console.log(rest)
    setHasPrevious(pageToLoad > 1);
    setProducts(() => {
      return itemCombineMethod === 'prepend' ?
        [...data, ...prevItems] :
        [...prevItems, ...data]
    });
  };

  const loadNext = () => {
    setPage(() => pageToLoad + 1)
    loadItems(pageToLoad, 'append');
  }

  const loadPrevious = () => {
    setPage(() => pageToLoad - 1)
    loadItems(pageToLoad, 'prepend');
  }

  const { observe } = useInView({
    onEnter: () => {
      loadNext();
    },
  });

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadItems(pageToLoad, 'append');
    initialPageLoaded.current = true;
  }, [loadItems])

  return {
    newItems,
    hasNext,
    hasPrevious,
    loadNext,
    loadPrevious,
    observe
  };
}