import React from 'react';
import {NavLink} from 'react-router-dom';

const Hero = () => {
  return (
    <section className="hero">
      <img src="/car.png" alt="" className="hero__img" />
      <h1 className="heading-1 hero__header hero__header--primary">
        Zapraszamy do <span className="u-color-primary">naszego sklepu!</span>
      </h1>
      <h2 className="heading-3 heading-3--dark hero__header hero__header--secondary">Oferujemy tylko nowe części najwyższej jakości. Są to produkty znanych na całym świecie, dostawców części samochodowych.</h2>
      <NavLink exact strict to="/nasza-oferta" className="btn btn__primary hero__btn">
        Sprawdź ofertę
      </NavLink>
    </section>
  );
};

export default Hero;
