import React from 'react';

const ContactInfo = () => {
  return (
    <div className="contact__info">
      <div>
        <h4 className="heading-4 contact__info-header">Telefon</h4>
        <a href="tel:+48943423370" className="contact__info-link">
          +48 94 34 233 70
        </a>
        <a href="tel:+48507130578" className="contact__info-link u-margin-bottom-small">
          +48 507 130 578
        </a>
      </div>
      <div>
        <h4 className="heading-4 contact__info-header">Adres</h4>
        <p className="contact__info-text">Moto-części Łuczak</p>
        <p className="contact__info-text">Helena Łuczak</p>
        <p className="contact__info-text">ul. Sienkiewicza 14</p>
        <p className="contact__info-text">78-100 Kołobrzeg</p>
      </div>
    </div>
  );
};

export default ContactInfo;
