import React from 'react';

import Hero from './Hero';
import Recommend from './Recommend';
import Content from './Content';
import ContentItem from './ContentItem';
import ContentDescription from './ContentDescription';

const Home = () => {
  return (
    <main className="home">
      <Hero></Hero>
      <Recommend></Recommend>
      <Content>
        <ContentItem
          title="Poszukujesz części do swojego samochodu?"
          desc="Tutaj zapewnimy ci profesjonalną obsługę oraz dostęp do części znanych i renomowanych firm. Nasz sklep zapewni klientom natychmiastowy dostęp do
            potrzebnych podzespołów oraz fachową i szybką obsługę."
        ></ContentItem>

        <ContentItem title="W naszej ofercie znajdziesz" subTitle="Części samochodowe do wszystkich marek pojazdów" img="/images/abc_marki.jpg">
          <ContentDescription>
            Posiadamy szeroki asortyment cześci samochodowych, każdy klient znajdzie części których poszukuje. Jeśli nie mamy na stanie to zapewniamy ich
            dostawę w terminie od 1-go dnia do maksymalnie 2 dni roboczych.
          </ContentDescription>
          <ContentDescription>
            Sprzedajemy klocki, tarcze hamulcowe, sprzegła, amortyzatory, akumulatory, filtry, oleje, rozrządy i wiele innych części.
          </ContentDescription>
        </ContentItem>

        <ContentItem reverse subTitle="Sprowadzanie części samochodowych pod indywidualne zamówienie" img="/images/sebastian-herrmann-NbtIDoFKGO8-unsplash.jpg">
          <ContentDescription>
            Naszymi dostawcami są renomowani producenci części do wszystkich rodzajów samochodów, również na pierwszy montaż.
          </ContentDescription>
          <ContentDescription>
            Są to między innymi firmy: ATE, SACHS, TRW, LUCAS, MONROE, BILSTEIN, MANN, INA, MAGNETI MARELLI, BOSCH, NGK, VALEO i wiele innych.
          </ContentDescription>
        </ContentItem>

        <ContentItem subTitle="Sprzedaż chemii oraz akcesoriów samochodowych" img="/images/KOSMETYKI-SAMOCHODOWE-K2-ZESTAW.jpeg">
          <ContentDescription>Posiadamy szeroki wybór chemii samochodowej i płynów eksploatacyjnych renomowanych firm.</ContentDescription>
          <ContentDescription>
            Sklep moto-części Łuczak mieści się przy ulicy Sienkiewicza 14 w Kołobrzegu. Zajmujemy się sprzedażą części motoryzacyjnych tudzież czesci.
          </ContentDescription>
          <ContentDescription>Nasz sklep motoryzacyjny oferuje wszystko co związane z samochodami w tym akcesoria i chemie samochodową.</ContentDescription>
        </ContentItem>
      </Content>
    </main>
  );
};

export default Home;
