import React from 'react';
import AboutUsContent from './AboutUsContent';
import GalleryContainer from './GalleryContainer';
const AboutUs = () => {
  const galleryItems = [
    {id: 1, url: '/images/photos/1.jpg'},
    {id: 2, url: '/images/photos/4.jpg'},
    {id: 3, url: '/images/photos/5.jpg'},
    {id: 4, url: '/images/photos/6.jpg'},
    {id: 5, url: '/images/photos/7.jpg'},
    {id: 6, url: '/images/photos/8.jpg'},
    {id: 7, url: '/images/photos/9.jpg'},
  ];

  return (
    <section className="about-us">
      <AboutUsContent></AboutUsContent>
      <GalleryContainer items={galleryItems}></GalleryContainer>
    </section>
  );
};

export default AboutUs;
