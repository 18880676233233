import { selector } from 'recoil';
//import getProducts from '../../components/api/products/products';
import products from '../products.json';

const fakeAPI = async (page = 0) => new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve(products.slice(page, 10));
    }, 2000);
})

export const productsState = selector({
    key: 'productsState',
    get: (page) => {
        //return await fakeAPI(page)
        return [];
    },
});

export const recommendProductsState = selector({
    key: 'promotionProductsState',
    get: ({get}) => {
        //return get(productsState).filter((el) => el.promotion)
        return [];
    },
});
