import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import ContactOpening from './ContactOpening';
import Map from './Map';

const Contact = ({errors, isSubmitting, touched}) => {
  return (
    <section className="contact">
      <h3 className="heading-3 heading-3--dark contact__header">Kontakt</h3>
      <div className="contact__form">
        <h3 className="heading-3 heading-3--dark contact__header--tablet">Formularz kontaktowy</h3>

        <ContactForm isSubmitting={isSubmitting} touched={touched} errors={errors}></ContactForm>
        <ContactInfo></ContactInfo>
      </div>

      <ContactOpening></ContactOpening>

      <h3 className="heading-3 heading-3--dark contact__header">Gdzie nas znaleźć?</h3>
      <Map className="contact__map"></Map>

      <p className="contact__text">
        Sklep moto-części Łuczak mieści się przy ulicy Sienkiewicza 14 w Kołobrzegu. Zajmujemy się sprzedażą części motoryzacyjnych tudzież czesci. Nasz sklep
        motoryzacyjny oferuje wszystko co związane z samochodami w tym akcesoria i chemie samochodową.
      </p>
    </section>
  );
};

export default Contact;
