import React from 'react';
import Navigation from './Navigation';
import {Link} from 'react-router-dom';

const Header = () => {
  return (
    <>
      <header className="header">
        <Link className="header__logo" to="/">
          <img src="/logo.png" alt="" className="header__img" />
        </Link>
        <Navigation></Navigation>
      </header>
      <div class="facebook-like-link">
        <div
          class="fb-like"
          data-href="https://www.facebook.com/motoluczak"
          data-layout="button_count"
          data-action="like"
          data-show-faces="true"
          data-share="true"
        ></div>
      </div>
    </>
  );
};

export default Header;
