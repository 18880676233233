import React, {useState} from 'react';
import Navigation from './Navigation';
import Map from './Map';
import Modal from './Modal';

const Footer = () => {
  const [isModalVisible, setModalVisibility] = useState(false);

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__copyright">
          <div className="footer__img-container">
            <img src="/logo.png" alt="" className="footer__img" />
          </div>
          <p className="footer__description">
            Sprzedażą części samochodowych zajmujemy się już od 20 lat. Naszym celem jest to by klient był zawsze zadowolony z naszej obsługi. W związku z tym
            nieustannie podnosimy swoje kwalifikacje zawodowe, dlatego zapewniamy najwyższy stopień profesjonalizmu w zakresie obsługi klienta.
          </p>
        </div>
        <div className="footer__menu">
          <Navigation></Navigation>
        </div>

        <Map className="footer__map"></Map>

        <p onClick={() => setModalVisibility(true)} className="footer__copyright-item">
          Copyright &copy; 2020 | Coding Tree
        </p>
      </div>
      {isModalVisible && <Modal setModalVisibility={setModalVisibility}></Modal>}
    </footer>
  );
};

export default Footer;
