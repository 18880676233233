import React from 'react';

const Modal = ({setModalVisibility}) => {
  const closeModal = (e) => {
    if (e.target === e.currentTarget) setModalVisibility(false);
  };
  return (
    <div onClick={(e) => closeModal(e)} className="modal">
      <div className="modal__box">
        <div className="modal__heading">
          <h3 className="heading-3 heading-3--light modal__header">Podziękowania dla twórców strony</h3>
          <i onClick={() => setModalVisibility(false)} className="fas fa-times modal__close"></i>
        </div>
        <div className="modal__body">
          <div className="modal__person">
            <img src="/coding-tree.png" alt="" className="modal__avatar" />
            <h3 className="heading-3 heading-3--dark modal__author">Coding Tree</h3>
            <a href="https://codingtree.pl/" className="modal__link">
              https://codingtree.pl/
            </a>
          </div>
          <div className="modal__person">
            <img src="/jozef.png" alt="" className="modal__avatar" />
            <h3 className="heading-3 heading-3--dark modal__author">Józef Rzadkosz - UX/UI Designer, Front-End Developer</h3>
            <a href="https://github.com/freestyle09" className="modal__link">
              https://github.com/freestyle09
            </a>
          </div>
          <div className="modal__person">
            <img src="/kaziu.png" alt="" className="modal__avatar" />
            <h3 className="heading-3 heading-3--dark modal__author">Kazimierz Bagrowski - Front-End Developer</h3>
            <a href="https://github.com/kazbag" className="modal__link">
              https://github.com/kazbag
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
