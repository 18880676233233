import React, { Suspense } from 'react';
import { useRecoilValue } from "recoil";
//import { productsState } from '../store/selectors/products';

import useProducts from '../hooks/useProducts';
import {productsState} from '../store/atoms/products';

const Box = ({id, name, description, image, cost, promotion}) => (
  <div className="box offer_box">
    <div className="box_image_container">
      <h3 className="title box_image_container_price">{cost}zł</h3>
      <img className="image box_image_container_image" src={image} alt="produkt" />
    </div>
    <div className="description_container">
      <h3 className="title description_container_title">{name}</h3>
      <p className="description description_container_description">{description}</p>
    </div>
  </div>
);

const Lower = () => {
  const products = useRecoilValue(productsState);
  return products.map((product, index) => (
        <Box
          key={index}
          id={product.id}
          name={product.name}
          description={product.description}
          image={product.image}
          cost={product.cost}
          promotion={product.promotion}
        />
      ))
  
}

const Offerki = () => {
  const { hasNext, hasPrevious, loadNext, loadPrevious, observe } = useProducts();
  return (
    <div className="offer__products">
            {/* {hasPrevious && 
        <button onClick={() => loadPrevious()}>Load Previous</button>
      } */}
    <Lower />
    {hasNext && 
       <button ref={observe} onClick={() =>loadNext()}>Load More</button>
      }
    </div>
  )
}

const Offer = () => {
  return (
    <div className="offer">
      <div className="offer__heading">
        <h3 className="heading-3 heading-3--dark">Nasza oferta</h3>
        <p className="offer__description">Posiadamy szeroki asortyment</p>
      </div>
      <Suspense fallback={<h3>Loading Products...</h3>}>
        <Offerki />
      </Suspense>
    </div>
  );
};

export default Offer;
