import React from 'react';
import Gallery from './Gallery';

const GalleryContainer = ({items}) => {
  return (
    <div className="about-us__gallery">
      <h2 className="heading-3 heading-3--dark about-us__heading">Galeria</h2>
      <Gallery array={items}></Gallery>
    </div>
  );
};

export default GalleryContainer;
