import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Offer from './components/Offer';
import Header from './components/Header';
import MainWrapper from './components/MainWrapper';
import Footer from './components/Footer';
import Formik from './components/Formik';

function App() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);
  return (
    <BrowserRouter>
    <RecoilRoot>
        <MainWrapper>
          <Header></Header>
          <Switch>
            <Route exact strict path="/" component={Home}></Route>
            <Route exact strict path="/o-nas" component={AboutUs}></Route>
            <Route exact strict path="/nasza-oferta" component={Offer}></Route>
            <Route exact strict path="/kontakt" component={Formik}></Route>
            <Redirect to="/"></Redirect>
          </Switch>
          <Footer></Footer>
        </MainWrapper>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
