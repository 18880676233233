import React from 'react';

const ContactOpening = () => {
  return (
    <div className="contact__opening">
      <h3 className="heading-3 heading-3--dark contact__header">Zapraszamy do naszego sklepu</h3>

      <p className="contact__text">Godziny otwarcia</p>
      <div className="contact__opening-item">
        <p className="contact__text">Poniedziałek - Piątek</p>
        <p className="contact__text">8:30 - 17:00</p>
      </div>
      <div className="contact__opening-item">
        <p className="contact__text">Sobota</p>
        <p className="contact__text">8:30 - 12:00</p>
      </div>
    </div>
  );
};

export default ContactOpening;
