import React, {useState} from 'react';
import FsLightbox from 'fslightbox-react';

const Gallery = ({array}) => {
  const [toggler, setToggler] = useState(false);
  const [slide, setSlide] = useState(0);

  const images = (array) => {
    const temp = [];
    array.map((el) => temp.push(el.url));
    return temp;
  };

  const openImage = (index) => {
    setToggler(!toggler);
    setSlide(index);
  };

  return (
    <div className="gallery">
      {array.map((el, index) => (
        <div key={index} className="gallery__item">
          <img className="gallery__img" onClick={(e) => openImage(index + 1)} src={el.url} alt="" />
          <div className="gallery__hover" onClick={(e) => openImage(index + 1)}>
            <svg className="gallery__icon">
              <use href="/search.svg#search-solid" />
            </svg>
          </div>
        </div>
      ))}
      <FsLightbox toggler={toggler} slide={slide} sources={images(array)} />
    </div>
  );
};

export default Gallery;
