import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';

const Navigation = () => {
  const [isMenuVisible, toggleMenuVisibility] = useState(false);

  const menuClass = () => {
    return isMenuVisible ? 'nav__list nav__list--visible' : 'nav__list';
  };
  return (
    <nav className="nav header__nav">
      <button onClick={() => toggleMenuVisibility(true)} className="nav__btn">
        <i className="fas fa-bars"></i>
      </button>

      <ul className={menuClass()}>
        <button onClick={() => toggleMenuVisibility(false)} className="nav__close">
          <i className="fas fa-times"></i>
        </button>
        <img src="/logo.png" alt="" className="nav__logo" />

        <NavLink onClick={() => toggleMenuVisibility(false)} exact strict to="/" className="nav__item">
          Strona główna
        </NavLink>
        <NavLink onClick={() => toggleMenuVisibility(false)} exact strict to="/o-nas" className="nav__item">
          O nas
        </NavLink>
        <NavLink onClick={() => toggleMenuVisibility(false)} exact strict to="/nasza-oferta" className="nav__item">
          Nasza oferta
        </NavLink>
        <NavLink onClick={() => toggleMenuVisibility(false)} exact strict to="/kontakt" className="nav__item">
          Kontakt
        </NavLink>
        <NavLink onClick={(e) => e.preventDefault()} exact strict to="/blog" className="nav__item nav__item--disabled">
          Blog
        </NavLink>
        <img src="/wave.svg" alt="" className="nav__wave" />
        <p className="nav__copyright">Copyright &copy; 2020 | Coding Tree</p>
        <a href="/login" class="nav__item nav__login">
          Zaloguj
        </a>
      </ul>
    </nav>
  );
};

export default Navigation;
