import React from 'react';
import {Field, ErrorMessage} from 'formik';

const Input = ({name, touched, label, error, type = 'text', component = 'input', placeholder}) => {
  const errorClass = (error, touched) => {
    return touched && error ? 'form__error' : '';
  };

  const fieldClassName = (component, error, touched = false) => {
    let output = '';
    output += errorClass(error, touched);
    output += component === 'textarea' ? ' form__textarea' : ' form__input';
    return output;
  };
  return (
    <div className={`form__control form__control--${component}`}>
      <label className="form__label" htmlFor={name}>
        {label}
      </label>
      <Field className={fieldClassName(component, error, touched)} placeholder={placeholder} type={type} component={component} name={name} id={name} />
      <ErrorMessage component={Error} name={name}></ErrorMessage>
    </div>
  );
};

const Error = ({children}) => {
  return <div className="form__error-field">{children}</div>;
};

export default Input;
