import { } from 'react';
import { useRecoilState } from "recoil";
import { productsState } from '../store/selectors/products';
import {useInfiniteLoading} from '../hooks/useInfiniteLoading';
import getProducts from '../api/products/products';

const useProducts = () => {
    const [products, setProducts] = useRecoilState(productsState);
    const { newItems: newProducts, hasNext, hasPrevious, loadNext, loadPrevious, observe } = useInfiniteLoading(
        products,
        getProducts,
        setProducts
    );
    // setProducts(newProducts);


    return { hasNext, hasPrevious, loadNext, loadPrevious, observe };

    return { hasNext: () => {}, hasPrevious: () => {}, loadNext: () => {}, loadPrevious: () => {} };
} 

export default useProducts;
